/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  lang?: string
  title?: string
  description?: string
  titleTemplate?: string
  schemaMarkup?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any[]
}
function SEO({
  lang,
  title,
  description,
  meta,
  schemaMarkup,
}: SEOProps): JSX.Element {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            lang
            title
            description
            author
            siteUrl
            contentUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const defaultTitle = site.siteMetadata?.title

  title = title ? title : `Document ESign - Free Online ESignature Services`

  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: `https://documentesign.com/`,
    },
    {
      property: `og:site_name`,
      content: `Document eSign`,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: `https://i0.wp.com/documentesign.com/wp-content/uploads/2020/11/Add-a-subheading.png`,
    },
    {
      property: `og:image:width`,
      content: `1200`,
    },
    {
      property: `og:image:height`,
      content: `630`,
    },
    {
      property: `og:image:alt`,
      content: `Home`,
    },
    {
      property: `og:image:type`,
      content: `image/png`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:site`,
      content: `@documentesign`,
    },
    {
      name: `twitter:creator`,
      content: `@documentesign`,
    },
    {
      name: `twitter:image`,
      content: `https://i0.wp.com/documentesign.com/wp-content/uploads/2020/11/Add-a-subheading.png`,
    },
    {
      name: "google-site-verification",
      content:
        site?.siteMetadata?.googleSiteVerification ||
        "8T35XrGQksJPj8EAGes0nCLcUNsAaZGzjnF6TwlnDj4",
    },
  ]

  meta = meta && meta.length ? meta : defaultMeta

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || defaultTitle}
      titleTemplate={title || defaultTitle}
      meta={meta}
    >
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
        integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
        crossOrigin="anonymous"
      />

      {Array.isArray(schemaMarkup) ? (
        schemaMarkup.map((schema, index) => (
          <script
            type="application/ld+json"
            key={`schema-markup${title}-${index}`}
          >
            {JSON.stringify(schema)}
          </script>
        ))
      ) : (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
      <script
        async
        src="https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.3/lottie.min.js"
        integrity="sha256-lZIvAx83WLHALrd5UIfVRAkDgVmAIxUt8JqPhNZIs4Q="
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  title: ``,
  titleTemplate: ``,
  description: ``,
}

export default SEO
